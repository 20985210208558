const baseUrl = 'https://api.match-flix.de';
export const brand = 'personaltotal';
export const isMatchflix = brand === 'matchflix'; // dont change!!!
export const isSenator = brand === 'senator';
export const isHrcg = brand === 'hrcg';
export const jobPostingListBySearchTermURL = `${baseUrl}/getJobPostingListBySearchTerm`;
// received structure
// [{
//   projectId: "",
//   projectNumber: "",
//   industrySector: "",
//   jobPosting: {
//     location: "", // untertitel
//     postalCode: "", // nur fürs filtern
//     city: "", // nur fürs filtern
//     title: ""
//   }
// }]
export const jobPostingURL = `${baseUrl}/getJobPosting`;
// received structure
// {
//   projectNumber: "",
//   jobPosting: {
//     location: "",
//     title: "",
//     description: "",
//   }
// }
export const jobPostingListURL = `${baseUrl}/getJobPostingList`;
// received structure
// [{
//   projectId: "",
//   projectNumber: "",
//   industrySector: "",
//   jobPosting: {
//     location: "", // untertitel,
//     postalCode: "", // nur fürs filtern,
//     city: "", // nur fürs filtern,
//     title: ""
//   }
// }]
export const updateCandidatePrivacyDetailsURL = `${baseUrl}/updateCandidatePrivacyDetails`;
export const validateDataPrivacySettingsUniqueLinkURL = `${baseUrl}/validateDataPrivacySettingsUniqueLink`;

export const dropDownFields = {
  mobility: ['Ja', 'Nein', 'Vielleicht, abhängig vom Angebot'],
  desiredRaiseForChange: Array.from({ length: (55 - 10) / 5 }, (_, i) => `${10 + i * 5}%`),
  desiredSalary: Array.from({ length: (205000 - 50000) / 5000 }, (_, i) =>
    new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      maximumSignificantDigits: 6
    }).format(50000 + i * 5000)
  ),
  channelForInformation: ['E-Mail', 'telefonisch', 'Soziale Medien', 'WhatsApp', 'egal'],
  motivationForChange: [
    'spannende Aufgabe',
    'Verbesserung des Gehalts',
    'nächster Karriereschritt',
    'Arbeitsweg verkürzen',
    'mehr Flexibilität',
    'private Gründe',
    'sonstiges'
  ],
  frequencyOfInformation: ['wöchentlich', 'einmal im Monat', 'alle zwei Monate', '2x im Jahr', 'jährlich']
};
